import React, { createContext, useContext } from 'react';

const SiteContext = createContext();

export const SiteProvider = ({ children }) => {
  const siteInfo = {
    siteName: "GatesOfMoolah.com",
    slogan: "Equipping Students for Success",
    info: "At GatesOfMoolah.com, we offer a wide range of high-quality school supplies to ensure every student is prepared for success. From notebooks to backpacks, we've got you covered.",
    aboutTitle: "About Us",
    aboutInfo: "GatesOfMoolah.com is dedicated to providing students, parents, and teachers with the best supplies for a successful academic journey. Our mission is to make education accessible and enjoyable for everyone. We believe in the power of education and are committed to supporting it with the best tools and resources available.",
    thankYouProduct: "We appreciate your interest in our products. Your support fuels our mission to empower students with quality supplies.",
    thankYouSubscription: "Thank you for subscribing! Stay tuned for exciting updates and exclusive offers to support your educational journey.",
    thankYouOrder: "Your order is appreciated! We're dedicated to ensuring your success with our quality products and excellent service.",
  };

  return (
    <SiteContext.Provider value={siteInfo}>
      {children}
    </SiteContext.Provider>
  );
};

// Create a custom hook to use the SiteContext
export const useSite = () => {
  return useContext(SiteContext);
};
