import React from 'react';
import { useSite } from '../context/SiteContext';
import styles from './Delivery.module.css'; // Assuming you have a CSS module for styling

const Delivery = () => {
  const { siteName } = useSite();

  return (
    <div className={styles.shippingInfo}>
      <h1>SHIPPING INFORMATION</h1>
      <p>Welcome to {siteName}! We are committed to delivering your school supplies promptly and securely. Here’s everything you need to know about our shipping policies:</p>
      
      <h2>1. ORDER PROCESSING TIME</h2>
      <p>Once you place your order, we start processing it immediately. Processing typically takes 1 to 2 business days, depending on order volumes and product availability. We’ll keep you informed throughout the process.</p>
      
      <h2>2. SHIPPING TIMES AND METHODS</h2>
      <p>Shipping times vary based on your location and the shipping method selected at checkout. Standard shipping typically takes 3-5 business days, while expedited options are available for faster delivery. Estimated delivery times are provided at checkout.</p>
      
      <h2>3. SHIPPING FEES</h2>
      <p>Shipping fees are calculated based on the weight and size of your order, as well as your location. We offer free shipping for orders over a certain amount, as indicated on our website.</p>
      
      <h2>4. TRACKING YOUR ORDER</h2>
      <p>Once your order has shipped, we’ll send you a tracking number via email so you can monitor its progress. You can also track your order status through your account on our website.</p>
      
      <h2>5. DAMAGED OR MISSING ITEMS</h2>
      <p>We take great care to ensure your order arrives in perfect condition. If you receive a damaged item or your order is missing items, please contact our support team immediately. We will arrange a replacement or refund as necessary.</p>
      
      <h2>6. HANDLING RETURNS</h2>
      <p>If you need to return an item, please contact our customer support team for a return authorization. Items must be returned in their original condition and packaging. Return shipping costs are the responsibility of the customer, unless the return is due to our error.</p>
      
      <h2>7. NON-RETURNABLE ITEMS</h2>
      <p>Some items, such as personalized or perishable products, are non-returnable. Please refer to the product page for specific return information.</p>
      
      <h2>8. ORDER CONFIRMATION</h2>
      <p>Please ensure your shipping details are accurate to avoid delays. Double-check all information during checkout.</p>
      
      <h2>9. ADDRESS ERRORS</h2>
      <p>If your shipment is returned due to an incorrect address or if you’re unavailable to receive it, we’ll contact you to arrange another delivery. This may involve additional fees.</p>
      
      <h2>10. INTERNATIONAL SHIPPING</h2>
      <p>We offer international shipping to select countries. Please be aware of any customs duties or import taxes that may apply, which are the responsibility of the recipient.</p>
      
      <p>For further details or assistance, please contact our customer support at <a href={`mailto:info@${siteName.toLowerCase()}`}>info@{siteName.toLowerCase()}</a>. We’re committed to ensuring your order arrives promptly and accurately.</p>
    </div>
  );
};

export default Delivery;
