import React from 'react';
import { useSite } from '../context/SiteContext';
import styles from './Privacy.module.css'; // Assuming you have a CSS module for styling

const Privacy = () => {
  const { siteName } = useSite();

  return (
    <div className={styles.privacyInfo}>
      <h1>PRIVACY COMMITMENT</h1>
      <p>At {siteName}, we prioritize your privacy and the security of your information. This document outlines our commitment to safeguarding your data and explains the practices you agree to by using our services and website.</p>

      <h2>1. INFORMATION COLLECTION</h2>
      <p><strong>Personal Data:</strong> When you make a purchase, we collect essential details such as your name, email address, shipping address, and payment information.</p>
      <p><strong>Interaction Data:</strong> We use cookies and similar technologies to collect data on how you interact with our website, including your IP address and browsing behavior.</p>

      <h2>2. INFORMATION USAGE</h2>
      <p><strong>Order Fulfillment:</strong> Your personal data is used to process and deliver your orders, and to keep you informed about your purchase status.</p>
      <p><strong>Customer Support:</strong> We use your contact information to respond to your inquiries and provide customer support.</p>
      <p><strong>Personalization:</strong> With your consent, we customize your shopping experience based on your past purchases and preferences.</p>
      <p><strong>Improvement:</strong> We analyze data to enhance our website and services, ensuring a better shopping experience for you.</p>

      <h2>3. INFORMATION DISCLOSURE</h2>
      <p><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us with shipping, payment processing, and other operational tasks. These providers are obligated to protect your data and use it solely for the services they provide to us.</p>
      <p><strong>Legal Requirements:</strong> We may disclose your information if required by law, or to protect our rights and the safety of our customers and the public.</p>

      <h2>4. SECURITY MEASURES</h2>
      <p>We implement robust security measures to protect your personal information from unauthorized access and misuse. However, please be aware that no internet transmission is completely secure.</p>

      <h2>5. YOUR PRIVACY CHOICES</h2>
      <p><strong>Opting Out:</strong> You can opt out of receiving promotional emails by following the unsubscribe instructions in any marketing email we send you, or by contacting us directly.</p>
      <p><strong>Cookies:</strong> You can manage your cookie preferences through your browser settings to control how we collect and use your interaction data.</p>

      <h2>6. CHILDREN'S PRIVACY</h2>
      <p>We do not knowingly collect personal information from children under the age of 16. If we become aware that we have inadvertently received such information, we will promptly delete it.</p>

      <h2>7. POLICY UPDATES</h2>
      <p>We may update this Privacy Policy from time to time to reflect changes in our practices. We encourage you to review this policy periodically. Continued use of our services after changes have been made signifies your acceptance of the updated policy.</p>

      <p>For any questions or more information about our privacy practices, please contact us at <a href={`mailto:info@${siteName.toLowerCase()}`}>info@{siteName.toLowerCase()}</a>. We are committed to protecting your privacy and addressing any concerns you may have.</p>
    </div>
  );
};

export default Privacy;
