import React from 'react';
import ProductCard from '../components/ProductCard';
import products from '../data/productsData';
import styles from './Home.module.css';
import Hero from '../components/Hero';

const Home = () => {
  const newProducts = products.filter(product => product.badge === 'New');
  const exclusiveProducts = products.filter(product => product.badge === 'Exclusive');

  return (
    <>
      <Hero/>
      <div id="products-section" className={styles.container}>
        <h1 id="new-products-section" className={styles.heading}>New Arrivals</h1>
        <div className={styles.grid}>
          {newProducts.map(product => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
      </div>
      <div className={styles.container}>
        <h1 id="exclusive-products-section" className={styles.heading}>Exclusive Offers</h1>
        <div className={styles.grid}>
          {exclusiveProducts.map(product => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Home;
