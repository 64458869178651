import image1 from '../assets/image1.webp';
import image2 from '../assets/image2.webp';
import image3 from '../assets/image3.webp';
import image4 from '../assets/image4.webp';
import image5 from '../assets/image5.webp';
import image6 from '../assets/image6.webp';
import image7 from '../assets/image7.webp';
import image8 from '../assets/image8.webp';

const products = [
  { id: 1, name: 'Personalized Pencil Set', price: '15.22', image: image1, badge: 'New', description: 'This personalized pencil set is perfect for adding a unique touch to your stationery collection. Ideal for school, work, or as a thoughtful gift.' },
  { id: 2, name: 'Ice Cream Cone Kawaii Ruler', price: '16.64', image: image2, badge: 'New', description: 'Make measuring fun with this adorable ice cream cone kawaii ruler. A delightful addition to any desk or pencil case.' },
  { id: 3, name: 'Reusable Sandwich and Snack Bag', price: '19.14', image: image3, badge: 'New', description: 'Eco-friendly and stylish, this reusable sandwich and snack bag is perfect for reducing waste and keeping your snacks fresh on the go.' },
  { id: 4, name: 'Personalized Dinosaur Lunch Bag', price: '28.14', image: image4, badge: 'New', description: 'Kids will love this personalized dinosaur lunch bag, featuring a fun design and plenty of space for all their lunchtime essentials.' },
  { id: 5, name: 'Affirmations Lunch Box Notes Set', price: '11.99', image: image5, badge: 'Exclusive', description: 'Brighten someone\'s day with these affirmations lunch box notes. Each set includes positive messages to encourage and inspire.' },
  { id: 6, name: 'Cute Kawaii Pencil Case', price: '12.50', image: image6, badge: 'Exclusive', description: 'Keep your stationery organized with this cute kawaii pencil case. Its charming design is sure to bring a smile to your face every time you use it.' },
  { id: 7, name: 'Soft Tip Highlighter Set', price: '35.90', image: image7, badge: 'Exclusive', description: 'Highlight with precision and style using this soft tip highlighter set. Perfect for studying, planning, and adding a pop of color to your notes.' },
  { id: 8, name: 'Macaron Creative Pencil Case', price: '12.06', image: image8, badge: 'Exclusive', description: 'This macaron-inspired creative pencil case is as functional as it is cute. Perfect for storing pens, pencils, and other small essentials.' },
 ];

export default products;
