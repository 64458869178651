import React from 'react';
import { useSite } from '../context/SiteContext';
import styles from './Terms.module.css'; // Assuming you have a CSS module for styling

const Terms = () => {
  const { siteName } = useSite();

  return (
    <div className={styles.termsInfo}>
      <h1>TERMS OF SERVICE</h1>
      <p>Welcome to {siteName}! By accessing and using our website, you agree to comply with the following terms and conditions. If you do not agree, please do not use our site.</p>
      
      <h2>1. INTELLECTUAL PROPERTY RIGHTS</h2>
      <p>All content on our website, including text, graphics, logos, icons, images, and videos, belongs to {siteName} and is protected by copyright and intellectual property laws. Unauthorized use or reproduction is strictly prohibited.</p>
      
      <h2>2. PERMISSIBLE USE</h2>
      <p>Our website is intended for your personal shopping and educational needs. You may not alter, distribute, or reproduce any content without our express written permission. Activities that could harm the functionality or security of our site, such as introducing malware, are prohibited.</p>
      
      <h2>3. INFORMATION ACCURACY</h2>
      <p>We strive to provide accurate and up-to-date information about our products. However, we cannot guarantee that all descriptions, prices, and availability details are error-free. We reserve the right to correct any errors without prior notice.</p>
      
      <h2>4. PRICING AND TRANSACTIONS</h2>
      <p>Prices listed on our site are subject to change and are displayed in the relevant currency. We offer secure payment options, and acceptable payment methods are outlined during the checkout process.</p>
      
      <h2>5. ORDERS AND CANCELLATIONS</h2>
      <p>Placing an order constitutes an offer to purchase, which we may accept or reject. We reserve the right to cancel orders for reasons such as stock unavailability or payment issues. Affected customers will be promptly notified and reimbursed.</p>
      
      <h2>6. SHIPPING AND RETURNS</h2>
      <p>We provide shipping services to various locations, with fees and delivery times varying by method and destination. Please refer to our detailed Shipping and Returns Policy for further information.</p>
      
      <h2>7. EXTERNAL LINKS</h2>
      <p>Our website may include links to external sites not operated by us. We are not responsible for the content or privacy practices of these external sites, so please exercise caution when accessing them.</p>
      
      <h2>8. LIMITATION OF LIABILITY</h2>
      <p>{siteName} and its affiliates are not liable for any direct, indirect, incidental, special, or consequential damages resulting from your use of our website or reliance on the information provided.</p>
      
      <h2>9. ACCEPTANCE OF TERMS</h2>
      <p>These terms are subject to change without prior notice. Your continued use of our site following any updates signifies your acceptance of the revised terms. If you have any questions about these terms, please contact us at <a href={`mailto:info@${siteName.toLowerCase()}`}>info@{siteName.toLowerCase()}</a>.</p>
    </div>
  );
};

export default Terms;
